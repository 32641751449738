import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './FocusAreas.css'

{/*First Modal*/}
function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Data Development & Growth strategy formulation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          The Zimbabwean & more broadly, African sports industry has a darth deficit in sports related research & 
          open data development which subsequently means that all purported domestic solution are premised on overseas 
          adopted, plagiarised ideas and concepts that don't fit the exact needs of local sports industry entities 
          and players.
          </p>
          <p>
          These ever-growing deficits in the requisite content, design thinking methodologies & contextualised systems 
          thinking needed to build solid solutions go on to continuously hamper all efforts by different stakeholders 
          as there are subsequent voids in  comprehensive quantitative evaluation of the economic development of the 
          sports industry, especially in the statistics of the output value of the sports industry, which lacks a 
          comprehensive understanding of the economic development of the industry. In the state of dual demand research 
          and application, it is imperative to reveal the publicity and education of industrial scale, industrial structure, 
          industrial function and industrial synergies across sport and related industries i.e the creative economy. In this 
          regard, we are building towards the establishment of an economic model for the sports industry which mainly 
          applies data developing technology. After allowing the extraction of information from the sports industry database, 
          it informs different industry growth pathways by fostering stakeholder collaboration based on tangible & locally 
          applicable data. All this will run on scientific and proficient processing methods to analyze a large number of
           diverse datasets, to process and analyze the economic development data of our sports industry in detail, and 
           conduct corresponding quantitative analysis according to the industry requirements which will guide and inform 
           real world solutions .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

{/*Second Modal*/}
function MySecondVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Sports Business & Industry Development
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            With how poorly the sports industry has performed & almost all failed to contribute significantly to economic development, our Business Development cluster exists to consults on behalf of corporations, governments, athletes & teams to strategically identify sports team oriented ventures and entertainment marketing, sponsorship, licensing and athlete partnership opportunities. We seek to bridge the ever growing gap between corporates & teams by being an intermediary as well as execution patner of contracts, events, growth venture initiatives & related patnerships through business process management. 
          </p>
          <p>
          Our team is committed to helping sports team owners,athletes and corporate clients to successfully navigate the many facets of the sports marketing, events and sponsorship industries as we build with governing boardies to develop solid and integrated sports policy frameworks that will drive sustainable growth.
          </p>
          <p>
          There's a great need for monitoring mechanisms for clubs' & athletes' sustained development that involves different facets such as financial modelling, literacy and operating model proficiency developments leveraging set Key-performance-indicators (KPIs) co-created by SportsCraft and our patners aforementioned.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

{/*Third Modal*/}
function MyThirdVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Sports Technology Innovation 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          Technology innovation plays a vital role in modern sport, yet often proceeds in an ad hoc manner, emerging as just an after thought by sports organisations rather than as a prioritized strategically programmed activity. In this regard its imperative that the industry begins to structure itself for technology adoption and integration, identifying key opportunities with start up innovators building local sports technology solutions whilst collaboratively building digital infrastructure rails that include sports analytics, media tools, design and digital marketing models, cross marketing, etc across different segments of the sports industry to streamline the inception of different technologies.
          </p>
          <p>
          Our model uses a needs assessment, context and stakeholder theory, which stipulates that Co-creating all the solutions with teams owners, governing orgs, athletes is the most effective and efficient way and delivering sustainable growth. 
          </p>
          <p>
          Together with structured enquiries on establishing technological literacy and identify translational and technology-ready opportunities to meet existing and emerging needs. It consolidates existing knowledge, translates exemplars of innovation from sport and other settings and highlights process innovation as being a vital element in the achievement of innovation. The model will then be applied in a tailored framework to professional sports organizations demonstrating its utility as an organizational tool for innovation planning and growth strategy development, all highlighting areas of best practices which will pave way for Identification of near and mid-term opportunities for innovation
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

{/*Fourth Modal*/}
function MyFourthVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Brand & digital marketing strategy development
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          As the sports sector & marketing segment changes, so should your digital marketing strategies. It’s important to stay aware of the changing landscape along with digital activities, and the new advancements in technology that can make sports organizations’ lives much easier.
          </p>
          <p>
          With the growth of digital technologies and as the sports industry goes global, today’s fans are different than before. They interact differently with their favorite brands, consume sports media and content in a shorter amount of time, move from one channel to another, and want to be fully involved.
          </p>
          <p>
          The main characteristic of these fluid fans is that they’ve become very demanding and very active across digital platforms, unapologetically sharing their opinions. They move and change quickly between channels and types of content online, they also want to be part of the game and to engage with their favorite teams. Digital technologies have made it easier for sports fans to discover and do more. Knowing these new habits, teams and leagues have to innovate to mobilize their fans and get them involved in a manner that allows for maximum monetization for team owners and deliver great content along with entertainment for the fans
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

{/*Fifth Modal*/}
  function MyFifthVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Partnership & Sponsorship Dealflow
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          Potential Investors in the sports industry view many sports, leagues & individual teams locally as risky zones to throw capital at, rather than commercially underdeveloped and ripe growth areas that can yield great returns. This calls for a drastic shift in perspective, only achievable through clear growth data metrics, brand appeal & market equity. This presents the opportunity for institutions like ours to use our expertise to significantly enhance clubs' business models and revenue generating architecture as well as frameworks to transform the entities’ long-term profitability and sustain fan base growth.
          </p>
          <p>
          Attracting significant investment towards sports business is a volatile balancing act, as it requires huge investment that if not well structured could well end up not delivering the sought after results. Sports value creation is heavily premised on fans' attention span hence the great need to work with clubs & individual sport athletes to build their playing brands, style and appeal making it all important for a partner like us who can draw together different stakeholders and contributors to create a higher standard of play, concerted league developments involving regulators, governing bodies, team owners, scouts and managers.
          </p>
          <p>
          With a sustained operating model we will eventually be able to deliver high value commercial deals across various professional and college\school sports in the short to long term
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

{/*Sixth Modal*/}
function MySixthVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Talent Management
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          Our aim is to deliver top tier innovation in the recruitment, management and grooming of athletes on and off their varying playing arenas by leveraging technology tools, working with key stakeholders such as performance and conditioning coaches, professional and school teams, etc in a collaborative framework that will ensure that no special talents are overlooked or stunted even after being discovered
          </p>
          <p>
          We believe in the transformative power of sports for economic growth & financial sustainability for athletes with a key focus on contributing to National Team success. It is in this regard that our RnD work is heavily focused in technologies for scouting, athlete brand development & media grooming, deployment & Deployment of professional scouts, All of which will work within our services ecosystem to deliver top talents to local teams, ship out talents to top sports markets in the USA and Europe Academies, College and semi Pro-Professional level leagues
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

{/*Seventh Modal*/}
function MySeventhVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Apparel design
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          We firmly believe that a key part of brand Equity which will spur growth and unlock the elusive youthful fan bases is apparel innovation along with brand appeal remodeling as well as renewal. Teams & athletes in first world economies have invested heavily into the aesthetic appeal of their franchises, changing logos, pushing the envelope on kit design, constantly pushing Research and development  into funky and sleek fan merchandise knowing fully well how fashion conscious the average sports fan is today, in this regard it is imperative that bespoke design innovation modules be applied by our local teams, leveraging the good pool of creatives we have as a nation. 
          </p>
          <p>
          We will actively seek to Provide clients with innovative performance sportswear design, sustainable & circular design that will correlate with efforts in the strategic  brand development clusters of our services offering including collaborations with artists
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

const FocusAreas = () => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [secondModalShow, setSecondModalShow] = React.useState(false);
  const [thirdModalShow, setThirdModalShow] = React.useState(false);
  const [fourthModalShow, setFourthModalShow] = React.useState(false);
  const [fifthModalShow, setFifthModalShow] = React.useState(false);
  const [sixthModalShow, setSixthModalShow] = React.useState(false);
  const [seventhModalShow, setSeventhModalShow] = React.useState(false);




  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true)

  return (
    <div class="fo_ar_section fo_ar_section_0 fo_ar_with_background et_section_regular snipcss-4acmJ">
    <div class="fo_ar_row fo_ar_row_0">
        <div class="fo_ar_column fo_ar_column_1_3 fo_ar_column_0  fo_ar_css_mix_blend_mode_passthrough">
            <div class="fo_ar_module fo_ar_text fo_ar_text_0  fo_ar_text_align_left fo_ar_bg_layout_light">
                <div class="fo_ar_text_inner">
                  <img class="fo_ar_logo" decoding="async" src="/images/sportscraft2.png" alt="" title="Artboard – 1"/>
                </div>
            </div>
        </div>
        <div class="fo_ar_column fo_ar_column_2_3 fo_ar_column_1  fo_ar_css_mix_blend_mode_passthrough et-last-child">
            <div class="fo_ar_with_border fo_ar_module fo_ar_text fo_ar_text_1  fo_ar_text_align_left fo_ar_bg_layout_light">
                <div class="fo_ar_text_inner">
                    <h2>Focus Areas</h2>
                </div>
            </div>
        </div>
    </div>
    <div class="fo_ar_row fo_ar_row_1">
        <div class="fo_ar_column fo_ar_column_1_3 fo_ar_column_2  fo_ar_css_mix_blend_mode_passthrough">
            <div class="fo_ar_module fo_ar_text fo_ar_text_2  fo_ar_text_align_left fo_ar_bg_layout_light">
                <div class="fo_ar_text_inner">
                <button className='fa_nav_button'><a href="/Overview" className='fa_button_text'>Overview</a></button>
                <button className='fa_nav_button fa_nav_button_border'><Link to="/" className='fa_button_text'>Focus Areas</Link></button>
                <button className='fa_nav_button'><Link to="/Services" className='fa_button_text'>Services</Link></button>
                <button className='fa_nav_button'><Link to="/Team" className='fa_button_text'>Team</Link></button>
                <button className='fa_nav_button'><Link to="/VisionMission" className='fa_button_text'>Vision & Mission</Link></button>
                </div>
            </div>
            <div class="fo_ar_with_border fo_ar_module fo_ar_text fo_ar_text_1_new  fo_ar_text_align_left fo_ar_bg_layout_light fo_ar_header_new">
                <div class="fo_ar_text_inner">
                    <h2>Focus Areas</h2>
                </div>
            </div>
        </div>
        <div class="fo_ar_column fo_ar_column_1_3 fo_ar_column_3  fo_ar_css_mix_blend_mode_passthrough">
            <div class="fo_ar_with_border fo_ar_module fo_ar_image fo_ar_image_0">
                <span class="fo_ar_image_wrap "><img decoding="async" src="/images/sportstakel11.png" alt="" title="Artboard – 1" onClick={() => setModalShow(true)}/></span>
            </div>
            <div class="fo_ar_module fo_ar_toggle fo_ar_toggle_0 fo_ar_toggle_item  fo_ar_toggle_close">
                <h5 class="fo_ar_toggle_title2" onClick={() => setModalShow(true)}>Data Development & Growth strategy formulation</h5>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </div>
        <div class="fo_ar_column fo_ar_column_1_3 fo_ar_column_4  fo_ar_css_mix_blend_mode_passthrough et-last-child">
            <div class="fo_ar_with_border fo_ar_module fo_ar_image fo_ar_image_1">
                <span class="fo_ar_image_wrap "><img decoding="async" src="/images/sportstake8.png" alt="" title="Artboard – 1" onClick={() => setSecondModalShow(true)}/></span>
            </div>
            <div class="fo_ar_module fo_ar_toggle fo_ar_toggle_1 fo_ar_toggle_item  fo_ar_toggle_close">
                <h5 class="fo_ar_toggle_title2" onClick={() => setSecondModalShow(true)}>Sports Business & Industry Development</h5>
                <MySecondVerticallyCenteredModal
                    show={secondModalShow}
                    onHide={() => setSecondModalShow(false)}
                />
            </div>
        </div>
    </div>
    <div class="fo_ar_row fo_ar_row_2">
        <div class="fo_ar_column fo_ar_column_1_3 fo_ar_column_5  fo_ar_css_mix_blend_mode_passthrough">
            <div class="fo_ar_with_border fo_ar_module fo_ar_image fo_ar_image_2">
                <span class="fo_ar_image_wrap "><img decoding="async" src="/images/sportstake9.png" alt="" title="Artboard – 1" onClick={() => setThirdModalShow(true)}/></span>
            </div>
            <div class="fo_ar_module fo_ar_toggle fo_ar_toggle_2 fo_ar_toggle_item  fo_ar_toggle_close">
                <h5 class="fo_ar_toggle_title2" onClick={() => setThirdModalShow(true)}>Sports Technology Innovation </h5>
                <MyThirdVerticallyCenteredModal
                    show={thirdModalShow}
                    onHide={() => setThirdModalShow(false)}
                />
            </div>
        </div>
        <div class="fo_ar_column fo_ar_column_1_3 fo_ar_column_6  fo_ar_css_mix_blend_mode_passthrough">
            <div class="fo_ar_with_border fo_ar_module fo_ar_image fo_ar_image_3">
                <span class="fo_ar_image_wrap "><img decoding="async" src="/images/sportstake10.png" alt="" title="Artboard – 1" onClick={() => setFourthModalShow(true)}/></span>
            </div>
            <div class="fo_ar_module fo_ar_toggle fo_ar_toggle_3 fo_ar_toggle_item  fo_ar_toggle_close">
                <h5 class="fo_ar_toggle_title2" onClick={() => setFourthModalShow(true)}>Brand & digital marketing strategy development</h5>
                <MyFourthVerticallyCenteredModal
                    show={fourthModalShow}
                    onHide={() => setFourthModalShow(false)}
                />
            </div>
        </div>
        <div class="fo_ar_column fo_ar_column_1_3 fo_ar_column_7  fo_ar_css_mix_blend_mode_passthrough et-last-child">
            <div class="fo_ar_with_border fo_ar_module fo_ar_image fo_ar_image_4">
                <span class="fo_ar_image_wrap "><img decoding="async" src="/images/sportstake13.png" alt="" title="Artboard – 1" onClick={() => setFifthModalShow(true)}/></span>
            </div>
            <div class="fo_ar_module fo_ar_toggle fo_ar_toggle_4 fo_ar_toggle_item  fo_ar_toggle_close">
                <h5 class="fo_ar_toggle_title2" onClick={() => setFifthModalShow(true)}>Partnership & Sponsorship Dealflow</h5>
                <MyFifthVerticallyCenteredModal
                    show={fifthModalShow}
                    onHide={() => setFifthModalShow(false)}
                />
            </div>
        </div>
    </div>
    <div class="fo_ar_row fo_ar_row_3">
        <div class="fo_ar_column fo_ar_column_1_2 fo_ar_column_8  fo_ar_css_mix_blend_mode_passthrough">
            <div class="fo_ar_with_border fo_ar_module fo_ar_image fo_ar_image_5">
                <span class="fo_ar_image_wrap "><img decoding="async" src="/images/sportstake14.png" alt="" title="Artboard – 1" onClick={() => setSixthModalShow(true)}/></span>
            </div>
            <div class="fo_ar_module fo_ar_toggle fo_ar_toggle_5 fo_ar_toggle_item  fo_ar_toggle_close">
                <h5 class="fo_ar_toggle_title2" onClick={() => setSixthModalShow(true)}>Talent Management</h5>
                <MySixthVerticallyCenteredModal
                    show={sixthModalShow}
                    onHide={() => setSixthModalShow(false)}
                />
            </div>
        </div>
        <div class="fo_ar_column fo_ar_column_1_2 fo_ar_column_9  fo_ar_css_mix_blend_mode_passthrough et-last-child">
            <div class="fo_ar_with_border fo_ar_module fo_ar_image fo_ar_image_6">
                <span class="fo_ar_image_wrap "><img decoding="async" src="/images/sportstake15.png" alt="" title="Artboard – 1" onClick={() => setSeventhModalShow(true)}/></span>
            </div>
            <div class="fo_ar_module fo_ar_toggle fo_ar_toggle_6 fo_ar_toggle_item  fo_ar_toggle_close">
                <h5 class="fo_ar_toggle_title2" onClick={() => setSeventhModalShow(true)}>Apparel design</h5>
                <MySeventhVerticallyCenteredModal
                    show={seventhModalShow}
                    onHide={() => setSeventhModalShow(false)}
                />
            </div>
        </div>
    </div>
</div>
  )
}

export default FocusAreas
