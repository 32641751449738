import React from 'react'
import { Link } from 'react-router-dom'

import './Services.css'

const Services = () => {
  return (
    <div class="se_section se_section_0 se_with_background et_section_regular snipcss-C8koX">
    <div class="se_row se_row_0">
        <div class="se_column se_column_2_5 se_column_0  se_css_mix_blend_mode_passthrough">
            <div class="se_module se_text se_text_0  se_text_align_left se_bg_layout_light">
                <div class="se_text_inner">
                    <img class="se_logo" decoding="async" src="/images/sportscraft9.png" alt="" title="Artboard – 1"/>
                </div>
            </div>
            <div class="se_module se_text se_text_1  se_text_align_left se_bg_layout_light">
                <div class="se_text_inner">
                <button className='se_nav_button'><Link to="/Overview" className='se_button_text'>Overview</Link></button>
                <button className='se_nav_button'><Link to="/FocusAreas" className='se_button_text'>Focus Areas</Link></button>
                <button className='se_nav_button se_nav_button_border'><Link to="/" className='se_button_text'>Services</Link></button>
                <button className='se_nav_button'><Link to="/Team" className='se_button_text'>Team</Link></button>
                <button className='se_nav_button'><Link to="/VisionMission" className='se_button_text'>Vision & Mission</Link></button>
                </div>
            </div>
            <div class="se_module se_image se_image_0">
                <span class="se_image_wrap se_image_wrap_column1 "><img decoding="async" src="/images/sportstake31.png" alt="" title="Artboard – 1"/></span>
            </div>
            <div class="se_module se_text se_text_2  se_text_align_center se_bg_layout_light">
                <div class="se_text_inner_p1">
                    <p id='se_text_inner_p'>Cultivate Value with us &amp; contribute to Industry growth</p>
                </div>
            </div>
            <div class="se_button_module_wrapper se_button_0_wrapper se_button_alignment_center se_module ">
                <a class="se_button se_button_0 se_bg_layout_light" href="https://docs.google.com/forms/d/1bkj1GbpPDDyPF5qiUZskwtcc1FLS-7GXTp2pV9nZq5Y/edit">Click For Sports Business Development</a>
            </div>
            <div class="se_button_module_wrapper se_button_1_wrapper se_button_alignment_center se_module ">
                <a class="se_button se_button_1 se_bg_layout_light" href="https://docs.google.com/forms/d/1HCvUmo9ftCqtasFsfrL96m4wDkXkpeYD956KX6jQz_0/edit">Click For Brand Strategy Development</a>
            </div>
            <div class="se_button_module_wrapper se_button_2_wrapper se_button_alignment_center se_module ">
                <a class="se_button se_button_2 se_bg_layout_light" href="https://docs.google.com/forms/d/1L-MZkQ3DBg8o8yqlCCN9T_glx5s4jQdZFIUO6_0C-cM/edit">Click For Athlete Career Development</a>
            </div>
        </div>
        <div class="se_column se_column_3_5 se_column_1  se_css_mix_blend_mode_passthrough et-last-child">
            <div class="se_module se_text se_text_3  se_text_align_left se_bg_layout_light">
                <div class="se_text_inner">
                    <h2>Focus Areas</h2>
                </div>
            </div>
            <div class="se_module se_divider se_divider_0 se_divider_position_ se_space">
                <div class="se_divider_internal"></div>
            </div>
            <div class="se_module se_blurb se_blurb_0  se_text_align_left  se_blurb_position_left se_bg_layout_light">
                <div class="se_blurb_content">
                    <div class="se_main_blurb_image"><span class="se_image_wrap se_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="512" height="512" src="/images/sportstake32.png" alt="" class="et-waypoint se_animation_top se_animation_top_tablet se_animation_top_phone wp-image-35 et-animated"/></span></div>
                    <div class="se_blurb_container">
                        <div class="se_blurb_description">
                            <p>Data oriented industry mapping, market intelligence surveys for data rich outputs, research and development on policies, growth frameworks</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="se_module se_divider se_divider_1 se_divider_position_ se_space">
                <div class="se_divider_internal"></div>
            </div>
            <div class="se_module se_blurb se_blurb_1  se_text_align_left  se_blurb_position_left se_bg_layout_light">
                <div class="se_blurb_content">
                    <div class="se_main_blurb_image"><span class="se_image_wrap se_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="512" height="512" src="/images/sportstake34.png" alt="" class="et-waypoint se_animation_top se_animation_top_tablet se_animation_top_phone wp-image-35 et-animated"/></span></div>
                    <div class="se_blurb_container">
                        <div class="se_blurb_description">
                            <p>Business models, Pitch deck development, investor readiness scope of works, digital and organizational Growth strategy development</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="se_module se_divider se_divider_2 se_divider_position_ se_space">
                <div class="se_divider_internal"></div>
            </div>
            <div class="se_module se_blurb se_blurb_2  se_text_align_left  se_blurb_position_left se_bg_layout_light">
                <div class="se_blurb_content">
                    <div class="se_main_blurb_image"><span class="se_image_wrap se_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="512" height="512" src="/images/sportstake33.png" alt="" class="et-waypoint se_animation_top se_animation_top_tablet se_animation_top_phone wp-image-35 et-animated"/></span></div>
                    <div class="se_blurb_container">
                        <div class="se_blurb_description">
                            <p>Networking and Events incl Scouting workshops, Data and analytics training for teams’ admin staff, kit launches, stakeholder roundtables and panel discussions, etc</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="se_module se_divider se_divider_3 se_divider_position_ se_space">
                <div class="se_divider_internal"></div>
            </div>
            <div class="se_module se_blurb se_blurb_3  se_text_align_left  se_blurb_position_left se_bg_layout_light">
                <div class="se_blurb_content">
                    <div class="se_main_blurb_image"><span class="se_image_wrap se_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="512" height="512" src="/images/sportstake35.png" alt="" class="et-waypoint se_animation_top se_animation_top_tablet se_animation_top_phone wp-image-35 et-animated"/></span></div>
                    <div class="se_blurb_container">
                        <div class="se_blurb_description">
                            <p>Talent scouting, development work on training, life coaching &amp; placement + Team and athlete performance scouting onto Player transfer dealflow, social media literacy &amp; financial management services along with Legal connections</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="se_module se_divider se_divider_4 se_divider_position_ se_space">
                <div class="se_divider_internal"></div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Services
